.full-page-image {
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  opacity: .9;
}

.bg-white {
  background: white;
}

.flex-ass {
  align-self: flex-start;
}

.table .table-wrapper { 
  overflow: inherit;
}

.header-bar .header-bar-logo { 
  height: auto !important;
}

@media only screen and (max-width: 1024px) { 
  .w-30-tab {
    width: 15% !important;
  }
}


.timeslotTypes  span { 
  padding: 4px;
}


.prioritised-list .dropdown-wrapper, .prioritised-list .input-group{ 
  margin-top: 0.1rem !important;
}

img#headerBarLogo { 
  width: 45px !important;
}

img.ml1.flex.w-70.w-30-tab { 

  width: 50% !important;
}

.timeButton { 
  padding: 0 6px;
  height: 25px;
  line-height: 20px;
  margin-right: 0.5rem !important;
}

.flex-centred.dropdown-wrapper.mx3 {
  right: 0px;
  position: absolute;
}

.label { 
  color: #2a2a2a;
  background: #c5c0c0;
  padding: 5px 10px;
  margin: 0px;
  margin-left: 0px;
  font-size: 1rem;
  height: 1.1rem;
  opacity: .9;
  border-radius: 8px;
}

.label.primary {
  background: #935bb5;
  color: #fff;
}

.label.secondary {
  background: #52b4ed;
  color: #fff;
}

.label.third {
  background: #eda152;
  color: #fff;
}

.label.forth {
  background: #9c52ed;
  color: #fff;
}

.label.fifth {
  background: #ed5252;
  color: #fff;
}



svg.primary {
  color: #935bb5;
}

svg.secondary {
  color: #52b4ed;
}

svg.third {
  color: #eda152;
}

svg.forth {
  color: #9c52ed;
}

svg.fifth {
  color: #ed5252;
}


.button.primary, button.primary {
  background: #935bb5;
}

.button.primary.inverted, button.primary.inverted {
  background: #fff;
  color: #935bb5;
  border: 1px solid #935bb5;
}

.checkbox-list .checkbox>input:checked ~ span {
  border: 2px solid #935bb5;
}

.checkbox-list .checkbox>span:after {
  border: solid #935bb5;
}

.side-menu .macq .nav-hover.active {
  border-right-color: #935bb5;
  background: rgba(147,91,181,0.15);
}

a, .anchor {
  color: #935bb5;
}

.side-menu .macq .nav-hover.active p, .side-menu .macq .nav-hover.active svg {
  color: #935bb5 !important;
}

.icon-button.gradient {
  background: -webkit-linear-gradient(315deg, #935bb5, #b991d2 50%, #afaef7);
  background: linear-gradient(135deg, #935bb5, #b991d2 50%, #afaef7);
}

.icon-button.gradient:hover, .icon-button {
  background: #935bb5;
}
.icon-button svg, .icon-button span {
  color:white;
}

.react-time-picker__inputGroup { 
  min-width: auto;
}



.react-time-picker__wrapper { 
overflow: visible;
    border-color: #ebedf2;
    color: #575962;
    border-radius: 4px;
    display: block;
    width: 100%;
    height: calc(2.95rem + 2px);
    padding: .85rem 1.15rem;
    font-size: 1rem;
    line-height: 1.25;
    background-color: #fff;
    background-clip: padding-box;
    border: 1px solid #ced4da;
    border-radius: 8px;
    -webkit-transition: border-color 0.15s ease-in-out,-webkit-box-shadow 0.15s ease-in-out;
    -webkit-transition: border-color 0.15s ease-in-out,box-shadow 0.15s ease-in-out;
    transition: border-color 0.15s ease-in-out,box-shadow 0.15s ease-in-out;
}


.react-time-picker__button:enabled { 
  display: none;
}

.is-sticky { 
  top: 10px !important;
}

.not-sticky.dropdown-wrapper { 
  z-index: 100;
}
.dropdown.right.wider { 
  z-index:1001;
}


.module_error .input-control { 
  background : red;
}